import { GetCheckoutResponse } from '@wix/ambassador-ecom-v1-checkout/types';
import { CheckoutService } from '../services/CheckoutService';
import { GetWalletDetailsResponse } from '@wix/ambassador-rise-integration-v1-wix-integration-provider/types';

export type ControllerState = {
  checkoutService: CheckoutService;
  status: ApplicationStatus;
  checkoutSlotProps?: CheckoutSlotProps;
  checkoutInfo?: GetCheckoutResponse;
  wallet?: GetWalletDetailsResponse;
};

export type CheckoutSlotProps = {
  checkoutId: string;
  refreshCheckoutCallback: () => void;
};

export enum ApplicationStatus {
  INITIALIZING = 'initializing',
  UPDATING = 'updating',
  IDLE = 'idle',
  Disabled = 'disabled',
}

export enum BIClickEvents {
  APPLY = 'apply',
  COLLAPSE = 'collapse',
  EXPAND = 'expand',
}
