import { updateCheckout } from '@wix/ambassador-ecom-v1-checkout/http';
import {
  Checkout,
  UpdateCheckoutResponse,
} from '@wix/ambassador-ecom-v1-checkout/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Transactions } from '../monitoring/transactions';

export interface UpdateCheckoutParams {
  checkout: Checkout;
  giftCardCode: string;
}

export class UpdateCheckoutApi {
  private readonly flowAPI: ControllerFlowAPI;

  constructor({ flowAPI }: { flowAPI: ControllerFlowAPI }) {
    this.flowAPI = flowAPI;
  }

  async updateCheckout({
    checkout,
    giftCardCode,
  }: UpdateCheckoutParams): Promise<UpdateCheckoutResponse> {
    this.flowAPI.fedops.interactionStarted(Transactions.ApplyStoreCredit);
    const response = await this.flowAPI.httpClient.request(
      updateCheckout({
        checkout,
        giftCardCode,
      }),
    );
    this.flowAPI.fedops.interactionEnded(Transactions.ApplyStoreCredit);

    return response.data;
  }
}
