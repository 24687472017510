import {
  MultiCurrencyPrice,
  GiftCard,
} from '@wix/ambassador-ecom-v1-checkout/types';
import { GetWalletDetailsResponse } from '@wix/ambassador-rise-integration-v1-wix-integration-provider/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { createAmountsFormatter } from './formateAmounts';

type StoreCreditAmounts = {
  redeemableStoreCredit: string;
  userTotalStoreCredit: string;
};

export function calculateStoreCreditAmounts({
  wallet,
  checkoutTotal,
  giftCard,
  currency,
  flowAPI,
}: {
  wallet: GetWalletDetailsResponse;
  checkoutTotal: MultiCurrencyPrice;
  giftCard: GiftCard;
  currency: string;
  flowAPI: ControllerFlowAPI;
}): StoreCreditAmounts {
  const formatAmounts = createAmountsFormatter({
    flowAPI,
    currency,
  });
  const totalCheckoutAmount = parseFloat(checkoutTotal.convertedAmount!);
  const walletBalance = parseFloat(wallet.giftCardInfo?.balance!);

  if (giftCard && wallet.giftCardId === giftCard.externalId) {
    const userBalance = Math.max(
      walletBalance - parseFloat(giftCard.amount?.convertedAmount!),
      0,
    );
    return formatAmounts({
      redeemableAmount: 0,
      balanceAmount: userBalance,
    });
  }

  const redeemable = Math.min(totalCheckoutAmount, walletBalance);
  return formatAmounts({
    redeemableAmount: redeemable,
    balanceAmount: walletBalance,
  });
}
