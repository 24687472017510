import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { GetCheckoutApi, GetCheckoutInfoArgs } from '../apis/GetCheckoutApi';
import { GetRiseWalletApi } from '../apis/GetRiseWalletApi';
import {
  UpdateCheckoutParams,
  UpdateCheckoutApi,
} from '../apis/UpdateCheckoutApi';

export class CheckoutService {
  private readonly getCheckoutApi: GetCheckoutApi;
  private readonly getRiseWalletApi: GetRiseWalletApi;
  private readonly updateCheckoutApi: UpdateCheckoutApi;
  private readonly refreshCheckoutCallback: () => void;

  constructor({
    flowAPI,
    refreshCheckoutCallback,
  }: {
    flowAPI: ControllerFlowAPI;
    refreshCheckoutCallback: () => void;
  }) {
    this.getCheckoutApi = new GetCheckoutApi({ flowAPI });
    this.getRiseWalletApi = new GetRiseWalletApi({ flowAPI });
    this.updateCheckoutApi = new UpdateCheckoutApi({ flowAPI });
    this.refreshCheckoutCallback = refreshCheckoutCallback;
  }

  async getCheckout({ checkoutId }: GetCheckoutInfoArgs) {
    return this.getCheckoutApi.getCheckout({ checkoutId });
  }

  async updateCheckout({ checkout, giftCardCode }: UpdateCheckoutParams) {
    const updatedCheckout = await this.updateCheckoutApi.updateCheckout({
      checkout,
      giftCardCode,
    });
    this.refreshCheckoutCallback();
    return updatedCheckout.checkout;
  }

  async getRiseWallet(currency: string) {
    return this.getRiseWalletApi.getWallet(currency);
  }
}
