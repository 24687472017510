
  import userController from '/home/builduser/work/988aeed2e9be6ea4/packages/rise-app-site-plugin/src/components/RiseAppSitePlugin/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  

  import { initI18nWithoutICU as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://5463bad232df48319111715b8ebe67cf@sentry.wixpress.com/5895',
    id: 'undefined',
    projectName: 'rise-app-site-plugin',
    teamName: 'ecom-rise-app',
    errorMonitor: true,
  };

  var experimentsConfig = {"scopes":["test"],"centralized":true};

  var translationsConfig = {"icuEnabled":false,"defaultTranslationsPath":"/home/builduser/work/988aeed2e9be6ea4/packages/rise-app-site-plugin/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var biConfig = null;

  var defaultTranslations = {"app.widget.welcome":"Hello","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to","checkoutSlot.redeemStoreCredit.codeAlreadyInUse.errorMessage":"This code is already in use","checkoutSlot.redeemStoreCredit.cta":"Apply","checkoutSlot.redeemStoreCredit.upToOrderTotal.warningMessage":"You can only use up to the total amount of your order","checkoutSlot.redeemStoreCredit.creditEmpty.errorMessage":"Your store credit is empty","checkoutSlot.redeemStoreCredit.title":"You have {amount} in store credit","checkoutSlot.redeemStoreCredit.title.new":"You have {{amount}} in store credit"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/988aeed2e9be6ea4/packages/rise-app-site-plugin/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "Rise App",
    componentName: "RiseAppSitePlugin",
    appDefinitionId: "56c4b3f5-a5f2-46bd-afb7-414136363b23",
    componentId: "f29f072e-b4e6-4820-8cb0-0d5ec2adb378",
    projectName: "rise-app-site-plugin",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/988aeed2e9be6ea4/packages/rise-app-site-plugin/src/components/RiseAppSitePlugin/controller.ts",
  };

  export const controller = _controller
  export default controller;
